import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight, FaExternalLinkAlt, FaFolder, FaGitlab } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Badge } from "./badge";


const Projects = () => {

  const { t } = useTranslation();
  const projects = [
    {
      title: i18next.t('projects.details.project1.title'),
      URL: "/projects/1",
      type: i18next.t('projects.type.personal'),
      body: i18next.t('projects.list.project1'),
      stack: [{ name: "React", link: "https://en.reactjs.org/" }, { name: "Tailwind", link: "https://tailwindcss.com/" }],
      isCollaboration: false,
    },
    {
      title: "MTKDC",
      URL: "/projects/2",
      type: i18next.t('projects.type.professional'),
      body: i18next.t('projects.list.project2'),
      stack: [{ name: "Angular", link: "https://angular.io/" }, { name: "NodeJS", link: "https://nodejs.org/en/" }],
      isCollaboration: true,
    },
    {
      title: i18next.t('projects.details.project3.title'),
      URL: "/projects/3",
      type: i18next.t('projects.type.personal'),
      body: i18next.t('projects.list.project3'),
      stack: [{ name: "React", link: "https://en.reactjs.org/" }, { name: "Tailwind", link: "https://tailwindcss.com/" }],
      isCollaboration: false,
    },
    {
      title: i18next.t('projects.details.project4.title'),
      URL: "/projects/4",
      type: i18next.t('projects.type.personal'),
      body: i18next.t('projects.list.project4'),
      stack: [{ name: "Angular", link: "https://angular.io/" }, { name: "Tailwind", link: "https://tailwindcss.com/" }, { name: "Firebase", link: "https://firebase.google.com/" }],
      isCollaboration: true,
    },
    {
      title: i18next.t('projects.details.project5.title'),
      URL: "/projects/5",
      type: i18next.t('projects.type.personal'),
      body: i18next.t('projects.list.project5'),
      stack: [{ name: "Symfony", link: "https://symfony.com/" }, { name: "VueJS", link: "https://vuejs.org/" }, { name: "Vuetify", link: "https://vuetifyjs.com/en/" }],
      isCollaboration: false,
    },
  ];

  return (
    <section id="projects" className={""} >
      <section className="w-full bg-gray-200 px-2.5 py-10 text-neutral-600 dark:bg-bgDarkest dark:bg-opacity-100 dark:text-light sm:px-5 sm:transition-all md:py-20 lg:py-40">
        <header className="mx-auto mb-10 flex max-w-screen-lg flex-col items-center md:items-start">
          <h3
            data-aos="fade"
            className="heading text-center text-4xl font-bold tracking-tight sm:transition-all md:text-left md:text-4xl lg:text-5xl"
          >
            {t('projects.title.part1')}
          </h3>
          <h3
            data-aos="fade"
            className="heading text-center text-4xl font-bold tracking-tight text-emerald-600 dark:text-emerald-400 sm:transition-all md:text-left md:text-4xl lg:text-5xl"
          >
            {t('projects.title.part2')}
          </h3>
          <p
            data-aos="fade"
            className="mt-2.5 max-w-[535px] text-center md:text-left"
          >
            {t('projects.paragraph')}
          </p>

        </header>
        <section className="mx-auto flex max-w-screen-lg flex-col">
          <div data-aos="fade" className="grid gap-0 sm:grid-cols-1 lg:grid-cols-2 sm:gap-5 ">
            {projects.map((x, i) => {
              return (
                <article className={''} key={x.title} data-aos="fade">

                  <div
                    key={i}
                    className="flex min-h-[138px] h-full transition-all  hover:-translate-y-1.5 select-none flex-col justify-between rounded-[4px] border-t-4 border-emerald-600 bg-white bg-opacity-70 shadow-md active:shadow-none dark:border-emerald-400 dark:bg-bgDarker dark:bg-opacity-100 sm:transition-all sm:hover:shadow-lg  "
                  >
                    <div className="flex flex-col gap-1 p-5   transition-all">
                      <div className={'flex justify-between items-center'}>
                        <div className={'flex flex-row items-end'}>
                          <FaFolder className={'h-10 w-10'} />
                          <div className={'flex flex-col  md:flex-row  items-start md:items-center'}>
                            <h4 className="heading text-2xl font-semibold ml-2">{x.title}</h4>
                            <Badge className=" md:mt-0 md:ml-4 ml-2 mt-1.5" color="blue">{x.type}</Badge>
                            {x.isCollaboration ?
                              <Badge className=" md:mt-0  ml-2  mt-1.5" color="blue">Collaboration</Badge>
                              : ''}
                          </div>
                        </div>

                        <Link to={x.URL} className="flex group  items-center gap-1 text-sm font-medium text-emerald-600 dark:text-emerald-400 sm:transition-all">
                          {t('projects.card.CTA')}
                          <span className={'group-hover:translate-x-2 mt-0.5 transition-all'}>
                            <FaArrowRight className="text-xs" />
                          </span>
                        </Link>
                      </div>
                      <p className="text-left text-sm dark:text-gray-200">
                        {x.body}
                      </p>
                    </div>
                    <div className="flex items-center justify-between gap-2.5 px-5 pb-5 text-[0.75rem] dark:text-gray-200 sm:transition-all">
                      <span className="hidden text-base text-neutral-400 hover:text-emerald-600 dark:hover:text-emerald-400 sm:block sm:transition-all">
                        <FaGitlab />
                      </span>
                      <div className="flex gap-2.5 text-[0.67rem] xs:text-[0.75rem]">
                        {x.stack.map((x, i) => {
                          return (
                            <a
                              href={x.link}
                              target={"_blank"}
                              rel={"noreferrer"}
                              className="flex items-center bg-gray-200 bg-opacity-50 py-0.5 px-2 shadow-sm dark:bg-bgDark xs:py-1 xs:px-2.5 sm:transition-all hover:text-white hover:bg-emerald-600 dark:hover:bg-emerald-400"
                              key={i}
                            >
                              <span>
                                {x.name}
                              </span>
                              <span className={"ml-2"}>
                                <FaExternalLinkAlt />
                              </span>
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </article>
              );
            })}
          </div>
        </section>
      </section>
    </section>
  );
};

export default Projects;
