import Add from '../assets/img/projects/cavavin/Add.png';
import HomeCavavin from '../assets/img/projects/cavavin/Home.png';
import Profile from '../assets/img/projects/cavavin/Profile.png';
import Signup from '../assets/img/projects/cavavin/Signup.png';
import Ajout from '../assets/img/projects/mtkdc/Ajout.png';
import Connexion from '../assets/img/projects/mtkdc/Connexion.png';
import Liste from '../assets/img/projects/mtkdc/Liste.png';
import Suppression from '../assets/img/projects/mtkdc/Suppression.png';
import Details from '../assets/img/projects/pokedex/Details.png';
import Evolve from '../assets/img/projects/pokedex/Evolve.png';
import Home from '../assets/img/projects/pokedex/Home.png';
import Stats from '../assets/img/projects/pokedex/Stats.png';
import Footer from '../assets/img/projects/portfoliov2/Footer.png';
import Hero from '../assets/img/projects/portfoliov2/Hero.png';
import Skills from '../assets/img/projects/portfoliov2/Skills.png';
import StudiesAndWorks from '../assets/img/projects/portfoliov2/StudiesAndWorks.png';
import Friend from '../assets/img/projects/post/Friend.png';
import Friends from '../assets/img/projects/post/Friends.png';
import HomePost from '../assets/img/projects/post/Home.png';
import ProfilePost from '../assets/img/projects/post/Profile.png';


export const projects = [
    {
        id: 1,
        title: 'projects.details.project1.title',
        goal: 'projects.details.project1.goal',
        body: 'projects.details.project1.body',
        images: [
            {
                src: Footer,
                legend: 'Footer',
            },
            {
                src: StudiesAndWorks,
                legend: 'Studies & Work',
            },
            {
                src: Skills,
                legend: 'Skills',
            },
            {
                src: Hero,
                legend: 'Hero',
            },
        ],
    },
    {
        id: 2,
        title: 'projects.details.project2.title',
        goal: 'projects.details.project2.goal',
        body: 'projects.details.project2.body',
        images: [
            {
                src: Suppression,
                legend: 'Suppression',
            },
            {
                src: Ajout,
                legend: 'Ajout',
            },
            {
                src: Liste,
                legend: 'Liste',
            },
            {
                src: Connexion,
                legend: 'Connexion',
            },
        ],
    },
    {
        id: 3,
        title: 'projects.details.project3.title',
        goal: 'projects.details.project3.goal',
        body: 'projects.details.project3.body',
        images: [
            {
                src: Stats,
                legend: 'Stats',
            },
            {
                src: Evolve,
                legend: 'Evolve',
            },
            {
                src: Details,
                legend: 'Details',
            },
            {
                src: Home,
                legend: 'Stats',
            },
        ],

    },
    {
        id: 4,
        title: 'projects.details.project4.title',
        goal: 'projects.details.project4.goal',
        body: 'projects.details.project4.body',
        images: [
            {
                src: HomeCavavin,
                legend: 'HomeCavavin',
            },
            {
                src: Profile,
                legend: 'Profile',
            },
            {
                src: Add,
                legend: 'Add',
            },
            {
                src: Signup,
                legend: 'Signup',
            },
        ],
    },
    {
        id: 5,
        title: 'projects.details.project5.title',
        goal: 'projects.details.project5.goal',
        body: 'projects.details.project5.body',
        images: [
            {
                src: Friend,
                legend: 'Friend',
            },
            {
                src: ProfilePost,
                legend: 'ProfilePost',
            },
            {
                src: Friends,
                legend: 'Friends',
            },
            {
                src: HomePost,
                legend: 'HomePost',
            },
        ],
    },
];


