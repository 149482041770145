import React from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";


const Form = () => {

  const { t } = useTranslation();

  return (
    <section
      id="contact"
      className="w-full bg-gray-200 px-2.5 py-10 dark:bg-bgDarkest sm:px-5 md:py-20 lg:py-44"
    >
      <div className="mx-auto flex max-w-screen-lg flex-row items-center justify-start">
        <div className="flex flex-col  items-start gap-10 ">
          <header>
            <h3
              data-aos="fade"
              className="heading text-center text-4xl font-bold tracking-tight sm:text-center sm:transition-all md:text-left md:text-4xl lg:text-5xl"
            >
              {t('contact.title.part1')},
            </h3>
            <h3
              data-aos="fade"
              className="heading text-center text-4xl font-bold tracking-tight text-emerald-600 dark:text-emerald-400 sm:text-center sm:transition-all md:text-left md:text-4xl lg:text-5xl"
            >
              {t('contact.title.part2')}
            </h3>
            <p
              data-aos="fade"
              className="mt-2.5 max-w-[535px] text-center sm:text-center md:text-left lg:mt-5"
            >
              {t('contact.paragraph')}
            </p>
          </header>
          <div className={"w-full flex justify-center md:justify-start"}>
            <section
              data-aos="fade"
              data-aos-delay="200"
              className={""}
            >
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:npttyn07@gmail.com"
                className="flex h-[44px] w-36 cursor-pointer items-center justify-center  rounded-md bg-emerald-600 hover:bg-emerald-700 text-md
                   font-semibold text-white dark:bg-emerald-400 dark:hover:bg-emerald-500  dark:text-light sm:transition-all sm:hover:shadow-md sm:active:shadow-none"
              >
                <FaEnvelope className={"mr-2 ml-1"} />
                {t('contact.contactMe')}
              </a>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Form;
