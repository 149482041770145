import i18next from "i18next";
import React from 'react';
import { useTranslation } from "react-i18next";
import { FaBehanceSquare, FaPaperclip } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Scrollspy from 'react-scrollspy';
import logo from "../assets/img/logo.svg";
import cv from "../assets/pdf/cv_nicolas_pattyn.pdf";
import resume from "../assets/pdf/resume_pattyn_nicolas.pdf";
import Toggle from "../context/ThemeToggle";

interface NavLink {
  name: string;
  link: string;
}

const Nav: React.FC = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  }

  const links: NavLink[] = [
    {
      name: i18next.t('nav.about'),
      link: '#about',
    },
    {
      name: i18next.t('nav.projects'),
      link: '#projects',
    },
    {
      name: i18next.t('nav.experience'),
      link: '#work',
    },
    {
      name: i18next.t('nav.skills'),
      link: '#skills',
    },
    {
      name: i18next.t('nav.contact'),
      link: '#contact',
    }
  ];

  return (
    <nav
      id={"nav"}
      data-aos="fade"
      className="fixed z-[9999]  flex h-[56px] w-full items-center border-b bg-white px-2.5 dark:border-borderDark dark:bg-bgDark sm:transition-all"
    >
      <div className="flex w-full max-w-screen-lg mx-auto items-center justify-between">
        <h1
          data-aos="fade"
          data-aos-delay="100"
          className="heading flex md:hidden lg:flex  items-center gap-1.5 text-lg font-semibold text-neutral-600 dark:text-light sm:transition-all"
        >
          <img alt={"Personnal logo credit to Riyan Hidayat "} className={"w-6 text-emerald-600"} src={logo} />
          <Link className="ml-3 text-xl" to="/">npa</Link>
        </h1>
        {
          location.pathname === '/' ? <div
            data-aos="fade"
            data-aos-delay="200"
            className="hidden md:flex w-full items-center justify-center sm:mx-auto"
          >
            <Scrollspy offset={-10} className={'flex'} items={['about', 'projects', 'work', 'skills', 'contact']}
              currentClassName="whitespace-nowrap text-white bg-emerald-600 dark:text-light dark:bg-emerald-400">
              {links.map((link) => {
                return (
                  <li key={link.name} className={"py-1.5 px-2.5 text-md ml-4  dark:bg-bgDarker font-medium rounded-md"}><a href={link.link}>{link.name}</a></li>
                );
              })}
            </Scrollspy>
          </div> : ''
        }

        <div
          data-aos="fade"
          data-aos-delay="300"
          className="flex items-center  justify-end gap-2.5 sm:transition-all md:ml-6"
        >
          <Toggle />
          <a
            target="_blank"
            rel="noreferrer"
            href={i18n.language === 'fr' ? cv : resume}
            className="flex h-[36px] cursor-pointer items-center justify-center gap-1.5 rounded-md bg-emerald-600 hover:bg-emerald-700 p-2.5 text-xs
          font-medium text-white dark:hover:bg-emerald-400 dark:hover:text-light dark:bg-bgDarker dark:text-light sm:transition-all sm:hover:shadow-md sm:active:shadow-none"
          >
            <span>
              <FaPaperclip />
            </span>
            <span>
              {t('nav.resume')}
            </span>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.behance.net/nicolaspattyn1"
            className="flex h-[36px] cursor-pointer items-center justify-center gap-1.5 rounded-md bg-emerald-600 hover:bg-emerald-700 p-2.5 text-xs
          font-medium text-white dark:hover:bg-emerald-400 dark:hover:text-light dark:bg-bgDarker dark:text-light sm:transition-all sm:hover:shadow-md sm:active:shadow-none"
          >
            <span>
              <FaBehanceSquare />
            </span>
            <span>
              Behance
            </span>
          </a>
          <div>
            {/* <div
              className={'cursor-pointer'}
              onClick={() => changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>
              {i18n.language === 'fr' ? <GB title="Swap to English" className="w-12 h-[36px] rounded-md " /> : <FR title="Swap to French" className="w-12 h-[36px] rounded-md" />}
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
