import React, { useEffect, useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";

const Form = () => {

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 280) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, [])

  return (
    <div className={'fixed bottom-3 right-5  hidden lg:block cursor-pointer'}>
      {isVisible &&
        <div onClick={scrollToTop} data-aos-delay="500" data-aos="fade">
          <FaArrowCircleUp className={'transition-all hover:scale-95 lg:h-12 w-12 text-emerald-600 dark:text-emerald-400'} />
        </div>
      }
    </div>
  );
};

export default Form;
