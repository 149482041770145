import React from 'react';
import profilePicture from '../assets/img/me.png';

interface PictureProps {
  // Ajouter des propriétés ici si nécessaire
}

const Picture: React.FC<PictureProps> = () => {
  return (
    <section className="w-full flex justify-center text-neutral-600 dark:text-light sm:transition-all">
      <div className="styledPic group">
        <div className="wrapper hover:delay-150 after:hover:bg-emerald-600 dark:after:hover:bg-emerald-400  after:hover:-translate-y-1 after:hover:-translate-x-1 sm:transition-all">
          <img
            className="img relative rounded-lg"
            src={profilePicture}
            alt="Headshot"
          />
        </div>
      </div>
    </section>
  );
};

export default Picture;
