import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { ThemeContext } from "./ThemeContext";

const Toggle: React.FC = () => {
  const themeContext = React.useContext(ThemeContext);

  if (!themeContext) {
    // Gestion du cas où ThemeContext est undefined
    return null;
  }

  const { theme, setTheme } = themeContext;

  return (
    <div className="ease-in-out sm:transition-all">
      {theme === "dark" ? (
        <button
          onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
          className="flex h-[36px] items-center justify-center gap-1.5 rounded-md bg-gray-100 px-6 text-xs font-medium text-neutral-600 active:shadow-none dark:bg-bgDarker dark:text-light sm:transition-all sm:hover:shadow-md sm:active:scale-95 sm:active:shadow-none"
        >
          <FaSun />
          <span className="whitespace-nowrap sr-only">Light mode</span>
        </button>
      ) : (
        <button
          onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
          className="flex h-[36px] items-center justify-center gap-1.5 rounded-md bg-gray-100 px-6 text-xs font-medium text-neutral-600 active:shadow-none dark:bg-bgDarker dark:text-light sm:transition-all sm:hover:shadow-md sm:active:scale-95 sm:active:shadow-none"
        >
          <FaMoon className="text-[0.75rem]" />{" "}
          <span className="whitespace-nowrap sr-only">Dark mode</span>
        </button>
      )}
    </div>
  );
};

export default Toggle;
