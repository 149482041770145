import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    //.use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'fr',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: {
                    nav: {
                        about: 'About',
                        projects: 'Projects',
                        experience: 'Experience',
                        skills: 'Skills',
                        contact: 'Contact',
                        resume: 'resume',
                    },
                    hero: {
                        title: {
                            part1: 'Hi, my name is',
                            part2: 'Nicolas',
                            part3: 'I build web applications'
                        },
                        profile: 'I\'m a French front-end developer. I love <span class="text-emerald-600 font-semibold dark:text-emerald-400">designing</span> and creating <span class="text-emerald-600 font-semibold dark:text-emerald-400">beautiful</span> and <span class="text-emerald-600 font-semibold dark:text-emerald-400">functional</span> websites as well as mobile applications.'
                    },
                    projects: {
                        title: {
                            part1: 'These are my',
                            part2: 'current projects'
                        },
                        paragraph: 'Here are some of the projects I have completed or am in the process of completing in my spare time. For the moment I don\'t share the source code of my different personal projects. To version my projects I only use Gitlab.',
                        information: {
                            title: 'Information',
                            content: 'I will soon add a more complete presentation of each project with screenshots.'
                        },
                        type: {
                            professional: 'Professional',
                            personal: 'Personal',
                        },
                        card: {
                            CTA: 'Visit',
                        },
                        list: {
                            project1: 'The second version of my portfolio',
                            project2: 'Redesign of the Myrmidons Taekwondo Club website',
                            project3: 'Web application to list the different pokemons of the pokedex',
                            project4: 'Application (web and mobile) for wine cellar management',
                            project5: 'Social network like Twitter'
                        },
                        details: {
                            project1: {
                                title: 'Portfolio V2',
                                goal: 'My professional showcase',
                                body: 'Conception and creation of my second professional showcase. This one having for goal to put forward what I can make as a developer.',
                            },
                            project2: {
                                title: 'Myrmidons Taekwondo Club',
                                goal: 'Redesign of the current website',
                                body: 'New design of the website for the Myrmidons Taekwondo Club to find all the essential information and to make "new skin", creation of a space to manage the site (schedules, courses, etc...). Creation of a store to order the goodies of the club. ',
                            },
                            project3: {
                                title: 'Pokedex',
                                goal: 'Gotta Catch \'Em All !',
                                body: 'Personal project realized thanks to the Pokemon api (<a href="https://pokeapi.co/" target="_blank" rel="noreferrer" class="fancy-link">https://pokeapi.co/</a>) allowing to find all the information on the different Pokemons. Having been accompanied in my childhood (and still today) by this series (as much by the video games as by the cartoons), I wanted through this project to practice collecting the different possible data',
                            },
                            project4: {
                                title: 'Cavavin',
                                goal: 'I cook with wine, sometimes I even add it to the food. (W.C. Fields)',
                                body: 'Personal project for a relative with the aim of managing several wine cellars and the different types of wines they can contain. The project is in the form of a CRUD with authentication (mdp, google, facebook). ',
                            },
                            project5: {
                                title: 'Post',
                                goal: 'Creation of a Social Network',
                                body: 'Personal project inspired by Twitter. This was the first project I worked on a lot. I found that making a social network was very simple as a concept, however you have to be creative to make it original.',
                            },
                        }
                    },
                    studiesandwork: {
                        title: {
                            part1: 'Where I\'ve',
                            part2: 'Studied & Worked'
                        },
                        list: {
                            first: {
                                title: 'Front-end  Developer',
                                company: 'Thera\'Soft',
                                range: '<p class="">September 2022 - <span class="text-emerald-600 font-semibold dark:text-emerald-400">Present</span></p>',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Development of the TsXcare CR module enabling radiologists to write oral reports and manage their appointments.</li>
                                            <li>Stack used : Angular</li>
                                        </ul>`,
                            },
                            second: {
                                title: 'Front-end & Back-end Developer',
                                company: 'Zelok',
                                range: '<p class="">September 2020 - September 2022</p>',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Development of various features for the Zelok platform
                                                designed for real estate agents to simplify the drafting of documents and the tenant application process</li>
                                            <li>Redesign of the Pass'Zelok application</li>
                                            <li>Stack used : Vue.js, Symfony 5, PHP, MySQL, Docker, Tailwind</li>
                                        </ul>`,
                                url: 'https://www.zelok.fr/',
                            },
                            third: {
                                title: 'Student',
                                company: 'Licence Professionnelle Développement Web et Big Data',
                                range: 'September 2020 - June 2021',
                                text: `<ul class="m-4 fancy-list">
                                        <li>Equivalent to a vocational bachelor’s degree specialize in Web Development and Big Data</li>
                                        <li>Specialization in web development and professionalization with
                                            the internship at Zelok.</li>
                                         <li>Obtaining the <a class="fancy-link " href="https://www.opquast.com/" target="_blank" rel="noreferrer">Opquast</a> 
                                         certification 910/1000 (ref. badge <a class="fancy-link" href="https://directory.opquast.com/fr/certificat/24ZFE0/" target="_blank" rel="noreferrer">#27ZFE0</a>)</li>
                                     </ul>`,
                                url: 'https://www.iut.unilim.fr/les-formations/licences-professionnelles/metiers-de-linformatique-systemes-dinformation-et-gestion-de-donnees-developpeur-dapplication-web-et-big-data/'
                            },
                            fourth: {
                                title: 'Backend Developer Assistant',
                                company: 'Emakina',
                                range: 'May 2020 - June 2020',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Development of a translation tool for the contents of an online store
                                                online store (pages, products, collections, etc...), as well as a module to activate an additional language on the online store</li>
                                            <li>Stack used : Symfony 5, Shopify</li>
                                         </ul>`,
                                url: 'https://www.emakina.com/fr-fr/home'
                            },
                            fifth: {
                                title: 'Student',
                                company: 'DUT Informatique de Limoges',
                                range: 'September 2018 - June 2020',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Equivalent to a two-year university full-course diploma in
                                                Information Technology, major Computing</li>
                                            <li>General computer training as well as an internship in a company.
                                                Several professional projects for clients in a group</li>
                                         </ul>`,
                                url: 'https://www.iut.unilim.fr/'
                            },
                        }
                    },
                    skills: {
                        title: {
                            part1: 'Some',
                            part2: 'Skills',
                        },
                        paragraph: 'Some of the skills I have acquired during my studies or as a self-taught person. I am a curious person, which allowed me to discover many languages and to update myself on the most recent frameworks'
                    },
                    contact: {
                        title: {
                            part1: 'Let\'s work together',
                            part2: 'Contact me',
                        },
                        paragraph: 'I am seeking a full-time salaried position in front-end web development. I am open to any type of web development work and even collaboration.',
                        contactMe: 'Contact me'
                    },
                    footer: {
                        external: 'External'
                    },
                }
            },
            fr: {
                translation: {
                    nav: {
                        about: 'A propos',
                        projects: 'Projets',
                        experience: 'Expérience',
                        skills: 'Compétences',
                        contact: 'Contact',
                        resume: 'CV',
                    },
                    hero: {
                        title: {
                            part1: 'Bonjour, je m\'appelle',
                            part2: 'Nicolas',
                            part3: 'Je crée des applications web'
                        },
                        profile: 'Je suis un développeur front-end. J\'aime <span class="text-emerald-600 font-semibold dark:text-emerald-400">concevoir</span> et créer des sites web <span class="text-emerald-600 font-semibold dark:text-emerald-400">beaux</span> et <span class="text-emerald-600 font-semibold dark:text-emerald-400">fonctionnels</span> ainsi que des applications mobiles.'
                    },
                    projects: {
                        title: {
                            part1: 'Mes projets',
                            part2: 'actuels'
                        },
                        paragraph: 'Voici quelques-uns des projets que j\'ai réalisés ou que je suis en train de réaliser pendant mon temps libre. Pour le moment je ne partage pas le code source de mes différents projets personnels. Pour versionner mes projets j\'utilise uniquement Gitlab',
                        information: {
                            title: 'Information',
                            content: 'Je vais bientôt ajouter une présentation plus complète de chaque projet avec des captures d\'écran.'
                        },
                        type: {
                            professional: 'Professionnel',
                            personal: 'Personnel',
                        },
                        card: {
                            CTA: 'Visiter',
                        },
                        list: {
                            project1: 'La seconde version de mon portfolio',
                            project2: 'Refonte du site web du Myrmidons Taekwondo Club',
                            project3: 'Application web pour répertorier les différents pokémons du pokedex',
                            project4: 'Application (web et mobile) pour la gestion des caves à vin',
                            project5: 'Réseau social inspiré de Twitter',
                        },
                        details: {
                            project1: {
                                title: 'Portfolio V2',
                                goal: 'Ma vitrine professionnelle',
                                body: 'Conception et création de ma deuxième vitrine professionnelle. Celle-ci ayant pour but de mettre en avant ce que je peux faire en tant que développeur.',
                            },
                            project2: {
                                title: 'Myrmidons Taekwondo Club',
                                goal: 'Refonte du site web',
                                body: 'Nouvelle conception du site web pour le Myrmidons Taekwondo Club pour trouver toutes les informations essentielles et faire "peau neuve", création d\'un espace pour administrer le site (horaires, cours, etc...). Mise en place d\'une boutique pour commander les goodies du club. ',
                            },
                            project3: {
                                title: 'Pokedex',
                                goal: 'Attrapez-les tous !',
                                body: 'Projet personnel réalisé grâce à l\'api Pokemon (<a href="https://pokeapi.co/" target="_blank" rel="noreferrer" class="fancy-link">https://pokeapi.co/</a>) permettant de trouver toutes les informations sur les différents Pokemons. Ayant été accompagné dans mon enfance (et encore aujourd\'hui) par cette série (autant par les jeux vidéos que par les dessins animés), j\'ai voulu à travers ce projet m\'exercer à récolter les différentes données possible',
                            },
                            project4: {
                                title: 'Cavavin',
                                goal: 'Je cuisine avec du vin, parfois je l\'ajoute même aux aliments. (W.C. Fields)',
                                body: 'Projet personnel pour un proche ayant pour but de gérer plusieurs caves à vins et les différents types de vins qu\'elles peut comporter. Le projet est sous forme d\'un CRUD avec une authentification (mdp, google, facebook). ',
                            },
                            project5: {
                                title: 'Post',
                                goal: 'Création d\'un Réseau social',
                                body: 'Projet personnel s\'inspirant de Twitter. C\'était le premier projet sur lequel j\'ai beaucoup travaillé. J\'ai trouvé que faire un réseau social était très simple comme concept, cependant il faut avoir les idées pour le rendre originale.',
                            },
                        }
                    },
                    studiesandwork: {
                        title: {
                            part1: 'Où j\'ai',
                            part2: 'Étudié et Travaillé'
                        },
                        list: {
                            first: {
                                title: 'Front-end  Developer',
                                company: 'Thera\'Soft',
                                range: '<p class="">Septembre 2022 - <span class="text-emerald-600 font-semibold dark:text-emerald-400">Actuellement</span></p>',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Développement du module TsXcare CR permettant à des médecins radiologues de rédiger des comptes-rendus oralement, gérer leurs rendez-vous</li>
                                            <li>Stack utilisée : Angular</li>
                                        </ul>`,
                            },
                            second: {
                                title: 'Front-end & Back-end Developer',
                                company: 'Zelok',
                                range: '<p class="">Septembre 2020 - Septembre 2022</p>',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Développement de diverses fonctionnalités pour la plateforme Zelok destinée aux agents immobiliers afin de leur simplifier la rédaction des documents et du parcours de candidatures des locataires</li>
                                            <li>Refonte de l'application Pass'Zelok</li>
                                            <li>Stack utilisée : Vue.js, Symfony 5, PHP, MySQL, Docker, Tailwind</li>
                                        </ul>`,
                                url: 'https://www.zelok.fr/',
                            },
                            third: {
                                title: 'Élève',
                                company: 'Licence professionnelle Développeur d\'Applications Web et Big Data en Alternance',
                                range: 'Septembre 2020 - Juin 2021',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Apprentissage de différents frameworks front-end et back-end</li>
                                            <li>Spécialisation en développement web et professionnalisation avec l'alternance chez Zelok.</li>
                                             <li>Obtention de la certification <a class="fancy-link " href="https://www.opquast.com/" target="_blank" rel="noreferrer">Opquast</a> 
                                              910/1000 (ref. badge <a class="fancy-link" href="https://directory.opquast.com/fr/certificat/24ZFE0/" target="_blank" rel="noreferrer">#27ZFE0</a>)</li>
                                         </ul>`,
                                url: 'https://www.iut.unilim.fr/les-formations/licences-professionnelles/metiers-de-linformatique-systemes-dinformation-et-gestion-de-donnees-developpeur-dapplication-web-et-big-data/'
                            },
                            fourth: {
                                title: 'Backend Developer Assistant',
                                company: 'Emakina',
                                range: 'Mai 2020 - Juin 2020',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Développement d'un outil de traduction pour des contenus d'une boutique en ligne sur Shopify (pages, produits, collections, etc...), ainsi qu'un module pour activer une langue supplémentaire sur la boutique en ligne</li>
                                            <li>Stack utilisée : Symfony 5, Shopify</li>
                                         </ul>`,
                                url: 'https://www.emakina.com/fr-fr/home'
                            },
                            fifth: {
                                title: 'Élève',
                                company: 'DUT Informatique de Limoges',
                                range: 'Septembre 2018 - Juin 2020',
                                text: `<ul class="m-4 fancy-list">
                                            <li>Une formation générale en informatique ainsi qu'un stage en entreprise.
                                                Plusieurs projets professionnels pour des clients en groupe</li>
                                         </ul>`,
                                url: 'https://www.iut.unilim.fr/les-formations/licences-professionnelles/metiers-de-linformatique-systemes-dinformation-et-gestion-de-donnees-developpeur-dapplication-web-et-big-data/'
                            },
                        }
                    },
                    skills: {
                        title: {
                            part1: 'Mes',
                            part2: 'Compétences',
                        },
                        paragraph: 'Certaines des compétences que j\'ai acquises au cours de ma scolarité ou en tant qu\'autodidacte. Je suis une personne curieuse, ce qui m\'a permis de découvrir de nombreux langages et de me mettre à jour sur les frameworks les plus récents.'
                    },
                    contact: {
                        title: {
                            part1: 'Travaillons ensemble',
                            part2: 'Contactez-moi',
                        },
                        paragraph: 'Je suis à la recherche d\'un poste salarié à temps plein en tant que développeur web front-end. Je suis  aussi ouvert à tout type de travail de développement web et même à la collaboration.',
                        contactMe: 'Me contacter'
                    },
                    footer: {
                        external: 'Externes'
                    },
                }
            }
        }
    });

export default i18n;
