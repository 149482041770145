import React from "react";
import { SiAngular, SiAntdesign, SiCsswizardry, SiDocker, SiHtml5, SiJavascript, SiMysql, SiNodedotjs, SiReact, SiSymfony, SiTailwindcss, SiVuedotjs } from "react-icons/si";

export interface Skill {
    title: React.ReactNode;
    name: string;
    color: string;
    darkHover: string;
    colorblur: string;
    darkText: boolean;
    border: string;
}

export const skillsList: Skill[] = [
    {
        title: <SiAngular />,
        name: "Angular",
        color: "bg-red-500 ",
        darkHover: "dark:hover:text-red-500",
        colorblur: "bg-red-500 ",
        darkText: false,
        border: "md:border-red-500 ",
    },
    {
        title: <SiCsswizardry />,
        name: "CSS",
        color: "bg-[#379AD5] ",
        darkHover: "dark:hover:text-[#379AD5]",
        colorblur: "bg-[#379AD5] ",
        darkText: false,
        border: "md:border-[#379AD5] ",
    },
    {
        title: <SiReact />,
        name: "React ",
        color: "bg-[#61DBFB] ",
        darkHover: "dark:hover:text-[#61DBFB]",
        colorblur: "bg-[#61DBFB] ",
        darkText: true,
        border: "md:border-[#61DBFB]",
    },
    {
        title: <SiTailwindcss />,
        name: "Tailwind",
        color: "bg-[#16BECB] ",
        darkHover: "dark:hover:text-[#16BECB]",
        colorblur: "bg-[#16BECB] ",
        darkText: false,
        border: "md:border-[#16BECB]",
    },
    {
        title: <SiNodedotjs />,
        name: "NodeJS ",
        color: "bg-[#529D43] ",
        colorblur: "bg-[#529D43] ",
        darkHover: "dark:hover:text-[#529D43]",
        darkText: true,
        border: "md:border-[#529D43]",
    },
    {
        title: <SiJavascript />,
        name: "JavaScript",
        color: "bg-yellow-500 ",
        darkHover: "dark:hover:text-yellow-500",
        colorblur: "bg-yellow-500",
        darkText: true,
        border: "md:border-yellow-500",
    },
    {
        title: <SiHtml5 />,
        name: "HTML5",
        color: "bg-[#F16524]",
        darkHover: "dark:hover:text-[#F16524]",
        colorblur: "bg-[#F16524]",
        darkText: false,
        border: "md:border-[#F16524] ",
    },
    {
        title: <SiVuedotjs />,
        name: "VueJS",
        color: "bg-[#3FB984] ",
        darkHover: "dark:hover:text-[#3FB984]",
        colorblur: "bg-[#3FB984]",
        darkText: true,
        border: "md:border-[#3FB984]",
    },
    {
        title: <SiSymfony />,
        name: "Symfony",
        color: "bg-black",
        darkHover: "dark:hover:text-white",
        colorblur: "bg-black",
        darkText: true,
        border: "md:border-black",
    },
    {
        title: <SiDocker />,
        name: "Docker",
        color: "bg-[#129FDB] ",
        darkHover: "dark:hover:text-[#129FDB]",
        colorblur: "bg-[#129FDB]",
        darkText: true,
        border: "md:border-[#129FDB]",
    },
    {
        title: <SiMysql />,
        name: "Mysql",
        color: "bg-[#00587E] ",
        darkHover: "dark:hover:text-emerald-400",
        colorblur: "bg-[#00587E]",
        darkText: true,
        border: "md:border-[#00587E]",
    },
    {
        title: <SiAntdesign />,
        name: "UX/UI",
        color: "bg-indigo-500 ",
        darkHover: "dark:hover:text-indigo-500",
        colorblur: "bg-indigo-500 ",
        darkText: false,
        border: "md:border-indigo-500 ",
    },
];