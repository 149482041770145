import React from "react";
import Contact from "../components/Contact";
import Hero from "../components/Hero";
import Jobs from "../components/Jobs";
import Projects from "../components/Projects";
import Skills from "../components/Skills";

const Home: React.FC = () => {
  return (
    <div>
      <div className="pt-[56px]" />
      <Hero />
      <Projects />
      <Jobs />
      <Skills />
      <Contact />
    </div>
  );
};

export default Home;
