import { default as i18n, default as i18next } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaBehanceSquare, FaEnvelope, FaGitlab, FaLinkedin, FaLocationArrow, FaPaperclip } from "react-icons/fa";
import logo from "../assets/img/logo.svg";
import cv from "../assets/pdf/cv_nicolas_pattyn.pdf";
import resume from "../assets/pdf/resume_pattyn_nicolas.pdf";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer
      data-aos-offset="1000"
      className="select-none py-10 px-5 dark:bg-bgDarker lg:py-20 lg:px-10"
    >
      <div className="mx-auto max-w-screen-lg">
        <div className="grid gap-10 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <section className="col-span-2 grid sm:flex sm:flex-col md:col-span-4 lg:col-span-1">
            <h4
              data-aos="fade"
              className="mb-2.5 flex items-center gap-1.5 font-semibold"
            >
              <img alt={"Personnal logo credit to Riyan Hidayat "} className={"w-6 text-emerald-600 mr-1SS"} src={logo} />
              Nicolas Pattyn
            </h4>
            <div
              data-aos="fade"
              className=" text-justify text-sm text-gray-600 dark:text-gray-300 sm:w-3/5 lg:w-full"
              dangerouslySetInnerHTML={{ __html: i18next.t('hero.profile') }}
            >
            </div>
          </section>

          <section data-aos="fade" data-aos-delay="100" className={"lg:ml-16"}>
            <h4 className="mb-2.5 font-semibold">{t('footer.external')}</h4>
            <div className="flex flex-col items-start gap-2.5 text-sm text-gray-600 dark:text-gray-300">
              <div className={"fancy-link"}>
                <a
                  href="https://gitlab.com/nikhn"
                  rel="noreferrer"
                  target="_blank"
                  className="inline-flex flex-nowrap items-center justify-start gap-1.5 self-start whitespace-nowrap hover:text-emerald-600 dark:hover:text-emerald-400 sm:transition-all"
                >
                  <FaGitlab className="text-xs" />
                  Gitlab
                </a>
              </div>
              <div className={"fancy-link"}>
                <a
                  href="https://www.linkedin.com/in/nicolas-pttyn/"
                  rel="noreferrer"
                  target="_blank"
                  className="inline-flex flex-nowrap items-center justify-start gap-1.5 self-start whitespace-nowrap hover:text-emerald-600 dark:hover:text-emerald-400 sm:transition-all"
                >
                  <FaLinkedin className="text-xs" />
                  Linkedin
                </a>
              </div>
              <div className={"fancy-link"}>
                <a
                  href="https://www.behance.net/nicolaspattyn1"
                  rel="noreferrer"
                  target="_blank"
                  className="inline-flex flex-nowrap items-center justify-start gap-1.5 self-start whitespace-nowrap hover:text-emerald-600 dark:hover:text-emerald-400 sm:transition-all"
                >
                  <FaBehanceSquare className="text-xs" />
                  Behance
                </a>
              </div>
            </div>
          </section>
          <section data-aos="fade" data-aos-delay="200">
            <h4 className="mb-2.5 font-semibold">Information</h4>
            <div className="flex flex-col items-start gap-2.5 text-sm text-gray-600 dark:text-gray-300">
              <a
                href="https://www.limoges.fr/"
                rel="noreferrer"
                target="_blank"
                className="inline-flex  select-none flex-nowrap items-center justify-start gap-1.5 self-start whitespace-nowrap hover:text-emerald-600 dark:hover:text-emerald-400 sm:transition-all"
              >
                <FaLocationArrow className="text-xs" />
                France, Limoges
              </a>
              <div className={"fancy-link"}>
                <a
                  href="mailto:npttyn07@gmail.com"
                  rel="noreferrer"
                  target="_blank"
                  className="inline-flex flex-nowrap items-center justify-start gap-1.5 self-start whitespace-nowrap hover:text-emerald-600 dark:hover:text-emerald-400 sm:transition-all"
                >
                  <FaEnvelope className="text-xs" />
                  npttyn07@gmail.com
                </a>
              </div>
              <div className={"fancy-link"}>
                <a
                  href={i18n.language === 'fr' ? cv : resume}
                  target="_blank"
                  rel="noreferrer"
                  className="inline-flex flex-nowrap items-center justify-start gap-1.5 self-start whitespace-nowrap hover:text-emerald-600 dark:hover:text-emerald-400 sm:transition-all"
                >
                  <FaPaperclip className="text-xs" />
                  {t('nav.resume')}
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
