import i18next from "i18next";
import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";

interface Job {
  title: string;
  company: string;
  range: string;
  text: string;
  url?: string;
}

const Jobs: React.FC = () => {
  const { t } = useTranslation();

  const jobs: Job[] = [
    {
      title: i18next.t('studiesandwork.list.first.title'),
      company: i18next.t('studiesandwork.list.first.company'),
      range: i18next.t('studiesandwork.list.first.range'),
      text: i18next.t('studiesandwork.list.first.text'),
    },
    {
      title: i18next.t('studiesandwork.list.second.title'),
      company: i18next.t('studiesandwork.list.second.company'),
      range: i18next.t('studiesandwork.list.second.range'),
      text: i18next.t('studiesandwork.list.second.text'),
      url: i18next.t('studiesandwork.list.second.url')
    },
    {
      title: i18next.t('studiesandwork.list.third.title'),
      company: i18next.t('studiesandwork.list.third.company'),
      range: i18next.t('studiesandwork.list.third.range'),
      text: i18next.t('studiesandwork.list.third.text'),
      url: i18next.t('studiesandwork.list.third.url')
    },
    {
      title: i18next.t('studiesandwork.list.fourth.title'),
      company: i18next.t('studiesandwork.list.fourth.company'),
      range: i18next.t('studiesandwork.list.fourth.range'),
      text: i18next.t('studiesandwork.list.fourth.text'),
      url: i18next.t('studiesandwork.list.fourth.url')
    },
    {
      title: i18next.t('studiesandwork.list.fifth.title'),
      company: i18next.t('studiesandwork.list.fifth.company'),
      range: i18next.t('studiesandwork.list.fifth.range'),
      text: i18next.t('studiesandwork.list.fifth.text'),
      url: i18next.t('studiesandwork.list.fifth.url')
    },
  ];

  const [activeTabId, setActiveTabId] = useState<number>(0);
  const [tabFocus, setTabFocus] = useState<number | null>(null);
  const tabs = useRef<HTMLButtonElement[]>([]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowUp': {
        e.preventDefault();
        setTabFocus(tabFocus !== null ? tabFocus - 1 : 0);
        break;
      }

      case 'ArrowDown': {
        e.preventDefault();
        setTabFocus(tabFocus !== null ? tabFocus + 1 : 0);
        break;
      }

      default: {
        break;
      }
    }
  };

  return (
    <section id="work">
      <section className="w-full  px-2.5 py-10 text-neutral-600  dark:bg-opacity-100 dark:text-light sm:px-5 sm:transition-all md:py-20 lg:py-40">
        <section>
          <header className="mx-auto  mb-10 flex max-w-screen-lg flex-col items-center md:items-start">
            <h3
              data-aos="fade"
              className="heading text-center text-4xl font-bold tracking-tight sm:transition-all md:text-left md:text-4xl lg:text-5xl"
            >
              {t('studiesandwork.title.part1')}
            </h3>
            <h3
              data-aos="fade"
              className="heading text-center text-4xl font-bold tracking-tight text-emerald-600 dark:text-emerald-400 sm:transition-all md:text-left md:text-4xl lg:text-5xl"
            >
              {t('studiesandwork.title.part2')}
            </h3>
          </header>
          <section data-aos="fade" className="mx-auto flex max-w-screen-lg">
            <div className={'flex sm:flex-row flex-col  justify-center w-auto'}>
              <div role="tablist" aria-label={'Job Tabs'} onKeyDown={e => onKeyDown(e)} className={'relative w-full sm:w-max p-0 m-0 list-none flex flex-col'}>
                {jobs &&
                  jobs.map((job, i) => {
                    return (
                      <button
                        className={'transition-all flex items-center  w-auto p-4 md:p-6 border-l-2  bg-none text-md text-left hover:bg-emerald-600/20 focus:bg-emerald-600/20 ' +
                          'dark:hover:bg-emerald-400/20 dark:focus:bg-emerald-400/20 ' +
                          (activeTabId === i ? 'bg-emerald-600/20 dark:bg-emerald-400/20 border-emerald-600 dark:border-emerald-400' : 'border-neutral-500')}
                        key={i}
                        onClick={() => setActiveTabId(i)}
                        ref={el => (tabs.current[i] = el!)} // Non-null assertion
                        id={`tab-${i}`}
                        role="tab"
                        tabIndex={activeTabId === i ? 0 : -1}
                        aria-selected={activeTabId === i}
                        aria-controls={`panel-${i}`}>
                        <span>{job.company}</span>
                      </button>
                    );
                  })}
              </div>
              <div className={'relative w-full mt-6 sm:ml-5 sm:mt-0'}>
                {jobs &&
                  jobs.map((job, i) => {
                    return (
                      <div
                        key={i}
                        className={'w-full  h-auto sm:transition-all'}
                        id={`panel-${i}`}
                        role="tabpanel"
                        tabIndex={activeTabId === i ? 0 : -1}
                        aria-labelledby={`tab-${i}`}
                        aria-hidden={activeTabId !== i}
                        hidden={activeTabId !== i}
                      >
                        <h3 className={'text-2xl'}>
                          <span>{job.title}</span>
                          <span className="text-emerald-600 dark:text-emerald-400">
                            &nbsp;@&nbsp;
                            {
                              job.url ?
                                <a href={job.url ? job.url : ''} target="_blank" rel="noreferrer" className="fancy-link">
                                  {job.company}
                                </a>
                                :
                                <span className="">{job.company}</span>
                            }
                          </span>
                        </h3>
                        <div dangerouslySetInnerHTML={{ __html: job.range }} />
                        <div dangerouslySetInnerHTML={{ __html: job.text }} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Jobs;
