import React, { FC, ReactNode, useLayoutEffect } from "react";
import {
    Navigate,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";

import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Scroller from "./components/Scroller";
import Home from "./views/Home";
import ProjectDetails from "./views/ProjectDetails";

interface WrapperProps {
    children: ReactNode;
}

const Wrapper: FC<WrapperProps> = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return <>{children}</>;
};

const App: FC = () => {
    return (
        <Wrapper>
            <div className="bg-gray-100 text-neutral-600 dark:bg-bgDarker dark:text-light sm:transition-all">
                <Nav />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/projects/:id" element={<ProjectDetails />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
                <Footer />
                <Scroller />
            </div>
        </Wrapper>
    );
};

export default App;
