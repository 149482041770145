import React from "react";

import { useTranslation } from "react-i18next";
import { skillsList } from "../const/listSkills.const";



const Skills: React.FC = () => {
  const { t } = useTranslation();



  return (
    <section
      id="skills"
      className={
        "w-full  px-2.5 py-10 text-neutral-600  dark:bg-opacity-100 dark:text-light sm:px-5 sm:transition-all md:py-20 lg:py-40 lg:mb-20 "
      }
    >
      <header className="mx-auto mb-10 flex max-w-screen-lg flex-col items-center md:items-start">
        <h3
          data-aos="fade"
          className="heading text-center text-4xl font-bold tracking-tight sm:transition-all md:text-left md:text-4xl lg:text-5xl"
        >
          {t("skills.title.part1")}
          <span
            data-aos="fade"
            className="ml-4 heading text-center text-4xl font-bold tracking-tight text-emerald-600 dark:text-emerald-400 sm:transition-all md:text-left md:text-4xl lg:text-5xl"
          >
            {t("skills.title.part2")}
          </span>
        </h3>
        <p
          data-aos="fade"
          className="mt-2.5 max-w-[535px] text-center md:text-left"
        >
          {t("skills.paragraph")}
        </p>
      </header>
      <section className="mx-auto flex max-w-screen-lg flex-col">
        <section className="max-w-screen-lg text-neutral-600 dark:text-light sm:transition-all">
          <div className="grid grid-cols-4 gap-2.5">
            {skillsList.map((x) => {
              return (
                <div data-aos="fade" key={x.name}>
                  <div
                    className={`${x.color} ${x.darkHover} hover:scale-105 relative z-20 flex aspect-square w-full flex-col items-center justify-center gap-2.5 rounded-[4px]  bg-opacity-30 p-5 p-2.5 dark:border-bgDark dark:bg-bgDark dark:shadow-none  sm:h-[105px] sm:w-[105px] sm:p-10 sm:transition-all md:aspect-square`}
                  >
                    <span
                      className={`heading select-none text-2xl font-bold tracking-tight sm:transition-all md:text-3xl lg:text-4xl`}
                    >
                      {x.title}
                    </span>
                    <h3 className="heading select-none text-center text-[0.7rem] tracking-tight">
                      {x.name}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </section>
    </section>
  );
};

export default Skills;
