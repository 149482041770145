import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { projects } from "../const/listProjects.const";

interface Image {
    src: string;
    legend: string;
}

interface Subchapter {
    title: string;
    content: string;
}

interface Chapter {
    title: string;
    content: string;
    subchapters?: Subchapter[];
}

interface Project {
    id: number;
    title: string;
    goal: string;
    body: string;
    images?: Image[];
    chapters?: Chapter[];
}

const ProjectDetails: React.FC = () => {
    const { t } = useTranslation();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const isInDev = true;
    const params: Partial<{ id: string }> = useParams();
    const [modalState, setModalState] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>(null);

    function changeModalState(imageId: number | null) {
        setModalState(!modalState);
        setImageInModale(imageId);
    }

    function setImageInModale(id: number | null) {
        if (id !== null && selectedProject && selectedProject.images) {
            setSelectedImage(selectedProject.images[id].src);
        } else {
            setSelectedImage(null);
        }
    }

    useEffect(() => {
        if (params.id === undefined) return;
        const id = +params.id;
        let indexOfProject = projects.findIndex((project) => project.id === id);
        if (indexOfProject !== -1) {
            setSelectedProject(projects[indexOfProject]);
        }

    }, [params.id]);

    return (
        <section className={"bg-gray-200 dark:bg-bgDarkest"}>
            {selectedProject ? (
                <div>
                    {modalState === true ? (
                        <div
                            data-aos="fade"
                            id="defaultModal"
                            tabIndex={-1}
                            aria-hidden="true"
                            className="hidden bg-gray-900 bg-opacity-70 dark:bg-opacity-80 fixed inset-0 z-40 md:flex justify-center items-center  overflow-y-auto overflow-x-hidden fixed  z-50 w-full md:inset-0 h-modal md:h-full"
                        >
                            <div className={"absolute  px-3 rounded-full top-16 md:right-0 lg:right-4"}>
                                <FaTimes
                                    onClick={() => changeModalState(null)}
                                    className={" hover:scale-90 h-12 w-6 text-white cursor-pointer dark:text-lightDarker"}
                                />
                            </div>
                            <div className="relative mt-8 p-4  max-w-7xl  md:h-auto">
                                <div className="relative  h-full shadow">
                                    {selectedImage ? (
                                        <img className={"rounded-xl mt-4 md:w-[700px] lg:w-full lg:max-h-[800px]   "} src={selectedImage} alt={"zoomed_" + (selectedImage ? selectedImage : "")} />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="pt-[56px]" />
                    <section className="mx-auto max-w-screen-lg py-10 px-2.5 sm:px-5 md:py-20">
                        <div>
                            <div className={"absolute max-w-min top-[60px]  md:relative md:top-0 md:left-0"}>
                                <Link to="/#projects" className={""} data-aos="fade" data-aos-delay="450">
                                    <FaTimes className={"w-6 h-12 hover:scale-90 transition-all"} />
                                </Link>
                            </div>
                            <header className="mx-auto my-5 md:mt-0 flex max-w-screen-lg flex-col items-center">
                                <h3
                                    data-aos-delay="400"
                                    data-aos="fade"
                                    className="heading text-center text-4xl font-bold tracking-tight sm:transition-all md:text-4xl lg:text-5xl"
                                >
                                    {t(selectedProject.title)}
                                </h3>
                                <h3
                                    data-aos-delay="500"
                                    data-aos="fade"
                                    className="heading text-center text-4xl font-bold tracking-tight text-emerald-600 dark:text-emerald-400 sm:transition-all md:text-4xl lg:text-5xl"
                                >
                                    {t(selectedProject.goal)}
                                </h3>
                                <div data-aos-delay="600" data-aos="fade" dangerouslySetInnerHTML={{ __html: selectedProject ? i18next.t(selectedProject.body) : "" }} className="mt-2.5 max-w-[535px] text-center"></div>
                            </header>
                            <section data-aos-delay="700" data-aos="fade" className="mx-auto grid w-full ">
                                <section className="overflow-hidden text-gray-700 ">
                                    <div className="mx-auto md:container">
                                        {selectedProject ? (
                                            <div>
                                                {selectedProject.images ? (
                                                    <div className="flex flex-col md:flex-row flex-wrap w-full  -m-1 md:-m-2 justify-center">
                                                        <div className="flex flex-col md:flex-row md:flex-wrap md:w-1/2">
                                                            {selectedProject.images.map((image, index) => (
                                                                <div key={index} className={` w-full md:w-1/2 p-1 md:p-2  `}>
                                                                    <img
                                                                        onClick={() => changeModalState(index)}
                                                                        alt="gallery"
                                                                        className=" block object-cover object-center w-full h-full rounded-lg md:cursor-zoom-in"
                                                                        src={image.src}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </section>
                            </section>
                        </div>
                    </section>
                </div>
            ) : (
                ""
            )}
        </section>
    );
};

export default ProjectDetails;
