import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import Picture from "./Picture";

const Hero: React.FC = () => {
  const { t } = useTranslation();



  return (
    <header
      id={"about"}
      className="relative flex min-h-[calc(100vh-56px)] w-full items-center border-b px-2.5 dark:border-borderDark sm:px-5 sm:transition-all py-5 md:py-40 2xl:py-60"
    >
      <section className="mx-auto mb-[56px] flex max-w-screen-lg flex-col items-center md:gap-5 lg:flex-row lg:gap-10">
        <div className="w-full">
          <h2
            data-aos="fade"
            data-aos-delay="400"
            className="md:whitespace-nowrap heading mx-auto flex w-full justify-center  text-center text-4xl font-bold tracking-tight sm:transition-all md:text-5xl lg:justify-start lg:text-left lg:text-5xl"
          >
            {t('hero.title.part1')}
          </h2>
          <h2
            data-aos="fade"
            data-aos-delay="500"
            className="heading md:pt-3 lg:pt-0 text-center text-5xl font-bold tracking-tight text-emerald-600 dark:text-emerald-400 sm:transition-all md:text-5xl lg:text-left lg:text-6xl"
          >
            {t('hero.title.part2')}
          </h2>
          <h3
            data-aos="fade"
            data-aos-delay="600"
            className="heading text-center text-4xl lg:text-5xl font-bold tracking-tight dark:text-lightDarker sm:transition-all md:text-5xl lg:text-left lg:text-4xl"
          >
            {t('hero.title.part3')}
          </h3>
          <p
            data-aos="fade"
            data-aos-delay="700"
            className="mx-auto mt-2.5 max-w-[535px] text-center lg:mt-5 lg:text-left"
            dangerouslySetInnerHTML={{ __html: i18next.t('hero.profile') }}
          >
          </p>
        </div>
        <section
          data-aos="fade"
          data-aos-delay="800"
          className="mx-auto mr-auto lg:ml-8 flex w-full max-w-[450px] justify-center self-center pt-10 md:self-start md:pt-10 lg:pt-2.5"
        >
          <Picture />
        </section>
      </section>
    </header>
  );
};

export default Hero;
